<template>
  <Teleport to="body">
    <div
      ref="bottomSheet"
      class="bottom-sheet"
      :class="bottomSheetClass"
      :aria-hidden="!isShowSheet"
      role="dialog"
    >
      <transition>
        <div v-show="isShowSheet" class="bottom-sheet__overlay" @click="close" />
      </transition>
      <div
        ref="bottomSheetContent"
        :class="sheetContentClasses"
        :style="`height: ${computedHeight}vh; justify-content: ${spaceBetween}`"
      >
        <header ref="bottomSheetHeader" class="bottom-sheet__header">
          <div ref="bottomSheetDraggableArea" class="bottom-sheet__draggable-area">
            <div class="bottom-sheet__draggable-thumb" />
          </div>
          <slot name="header" />
        </header>
        <main ref="bottomSheetMain" class="bottom-sheet__main">
          <slot />
        </main>
        <footer ref="bottomSheetFooter" class="bottom-sheet__footer">
          <slot name="footer" />
        </footer>
      </div>
    </div>
  </Teleport>
</template>

<script setup lang="ts">
  import type { IBottomSheetComponent } from '@skeleton/types';

  interface IEvent {
    type: string;
    deltaY: number;
    isFinal: boolean;
    cancelable: boolean;
  }
  const props = defineProps<{
    height?: string;
    spaceBetween?: boolean;
    bottomSheetClass?: string,
    disableMainSwipe?: boolean;
  }>();

  const emit = defineEmits(['opened', 'closed', 'dragging-up', 'dragging-down']);

  const { $hammer } = useNuxtApp();

  const isShowSheet = ref(false);
  const sheetHeight = ref(0);
  const translateValue = ref(100);
  const isDragging = ref(false);
  const contentScroll = ref(0);
  const bottomSheet = ref<Maybe<IBottomSheetComponent>>();
  const bottomSheetHeader = ref<Maybe<IBottomSheetComponent>>();
  const bottomSheetMain = ref<Maybe<IBottomSheetComponent>>();
  const bottomSheetFooter = ref<Maybe<IBottomSheetComponent>>();
  const bottomSheetContent = ref<Maybe<IBottomSheetComponent>>();
  const bottomSheetDraggableArea = ref<Maybe<IBottomSheetComponent>>();
  const computedHeight = computed(() => (props.height ? props.height : '70'));
  const spaceBetween = computed(() => (props.spaceBetween ? 'space-between' : 'start'));

  const sheetContentClasses = computed(() => [
    'bottom-sheet__content',
    {
      'bottom-sheet__content--fullscreen': sheetHeight.value >= window.innerHeight,
      'bottom-sheet__content--dragging': isDragging.value,
    },
  ]);

  const sheetHeightString = computed(() =>
    sheetHeight.value && sheetHeight.value > 0 ? `${sheetHeight.value + 1}px` : 'auto'
  );
  const translateValueString = computed(() => `${translateValue.value}%`);
  const transitionDuration = ref(500);
  const transitionDurationString = computed(() => `${transitionDuration.value / 1000}s`);

  const open = async () => {
    translateValue.value = 0;
    document.documentElement.style.overflowY = 'hidden';
    document.documentElement.style.overscrollBehavior = 'none';
    isShowSheet.value = true;

    setTimeout(async () => {
      emit('opened');
    }, transitionDuration.value);
  };

  const close = async () => {
    isShowSheet.value = false;
    translateValue.value = 100;
    setTimeout(async () => {
      document.documentElement.style.overflowY = 'auto';
      document.documentElement.style.overscrollBehavior = '';
      emit('closed');
    }, transitionDuration.value);
  };

  const slots = useSlots();
  const isFooterEmpty = computed(() => typeof slots.footer !== 'function');

  const pixelToVh = (pixel: number) => (pixel / sheetHeight.value) * 100;

  const getVerticalPadding = (el: HTMLElement) => {
    const styles = window.getComputedStyle(el);
    return parseFloat(styles.getPropertyValue('padding-bottom')) + parseFloat(styles.getPropertyValue('padding-top'));
  };

  const getContentGaps = () => {
    const gap = parseFloat(window.getComputedStyle(bottomSheetContent.value, null).getPropertyValue('gap'));

    return isFooterEmpty.value ? gap : gap * 2;
  };

  const initHeight = async () => {
    await nextTick();

    sheetHeight.value =
      bottomSheetHeader.value!.offsetHeight +
      bottomSheetMain.value!.offsetHeight +
      bottomSheetFooter.value!.offsetHeight +
      getVerticalPadding(bottomSheetContent.value!) +
      getContentGaps();
  };

  const dragHandler = (event: IEvent, type: 'area' | 'main' | 'header') => {
    isDragging.value = true;

    const preventDefault = (e: Event) => {
      e.preventDefault();
    };

    if (event.deltaY > 0) {
      if (type === 'main' && event.type === 'panup') {
        translateValue.value = pixelToVh(event.deltaY);
        if (event.cancelable) {
          bottomSheetMain.value!.addEventListener('touchmove', preventDefault);
        }
      }

      if (type === 'main' && event.type === 'pandown' && contentScroll.value === 0) {
        translateValue.value = pixelToVh(event.deltaY);
      }

      if (type === 'area' || type === 'header') {
        translateValue.value = pixelToVh(event.deltaY);
      }

      if (event.type === 'panup') {
        emit('dragging-up');
      }
      if (event.type === 'pandown') {
        emit('dragging-down');
      }
    }

    if (event.isFinal) {
      bottomSheetMain.value!.removeEventListener('touchmove', preventDefault);

      if (type === 'main') {
        contentScroll.value = bottomSheetMain.value!.scrollTop;
      }
      isDragging.value = false;

      // If the bottom sheet is slided more than 50px, close the bottom sheet
      if (translateValue.value >= 50) {
        close();
      } else {
        translateValue.value = 0;
      }
    }
  };

  nextTick(() => {
    initHeight();

    const hammerHeaderInstance = new $hammer(bottomSheetHeader.value, {
      inputClass: $hammer.TouchMouseInput,
      recognizers: [[$hammer.Pan, { direction: $hammer.DIRECTION_VERTICAL }]],
    });

    const hammerAreaInstance = new $hammer(bottomSheetDraggableArea.value, {
      inputClass: $hammer.TouchMouseInput,
      recognizers: [[$hammer.Pan, { direction: $hammer.DIRECTION_VERTICAL }]],
    });

    hammerAreaInstance.on('panstart panup pandown panend', (e: IEvent) => {
      dragHandler(e, 'area');
    });

    hammerHeaderInstance.on('panstart panup pandown panend', (e: IEvent) => {
      dragHandler(e, 'header');
    });

    if (!props.disableMainSwipe) {
      const hammerMainInstance = new $hammer(bottomSheetMain.value, {
        inputClass: $hammer.TouchMouseInput,
        recognizers: [[$hammer.Pan, { direction: $hammer.DIRECTION_VERTICAL }]],
      });

      hammerMainInstance.on('panstart panup pandown panend', (e: IEvent) => {
        dragHandler(e, 'main');
      });
    }
  });

  defineExpose({
    open,
    close,
  });
</script>

<style scoped lang="scss">
  .bottom-sheet {
    --translateValue: v-bind('translateValueString');
    --sheetHeight: v-bind('sheetHeightString');
    --transitionDuration: v-bind('transitionDurationString');
  }
</style>

<style src="~/assets/styles/components/layout/bottom-sheet.scss" lang="scss" />
